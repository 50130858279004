/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Pensaba instalar Pikdev para programar PICs con mi nuevo PICkit2, para ello he utilizado este tutorial ", React.createElement(_components.a, {
    href: "http://pic-linux.foroactivo.net/otros-f19/pickit-2-trabajando-pikdev-v-110-t86.htm"
  }, "http://pic-linux.foroactivo.net/otros-f19/pickit-2-trabajando-pikdev-v-110-t86.htm"), " porcierto el foro tiene muy buena pinta, me ha gustado. Pero cual es el problema que el paquete de Pikdev está un poco anticuado y tiene una dependencia de kdelibs4-dev que no me gusta nada sobretodo porque vamos por la kdelibs5-dev que es la que utiliza KDE4 y como también estoy empezando programar para KDE4 tenia problemas de dependencias. La solucion es simple, se cambian la dependencia del archivo kdelibs4-dev por kdelibs5-dev. ¿Que como se hace esto? Pues antes que nada con el archivo descargado en home por ejemplo, creamos una carpeta temporal:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">mkdir temporal</code>"
    }
  })), "\n", React.createElement(_components.p, null, "En esta carpeta temporal desempaquetamos el deb:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">dpkg-deb --extract paquete.deb temporal</code>"
    }
  })), "\n", React.createElement(_components.p, null, "En nuestro caso donde pone paquete.deb sera el nombre del paquete pikdev_1.1.0-1_i386.deb."), "\n", React.createElement(_components.p, null, "Luego extraemos la parte de control."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">dpkg-deb --control paquete.deb temporal/DEBIAN</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Luego abrimos el archivo de texto temporal/DEBIAN/control y modificamos las dependencias, donde pone kdelibs4-dev ponemos kdelibs5-dev."), "\n", React.createElement(_components.p, null, "Después de esto solo queda reempaquetar la carpeta temporal."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">dpkg --build temporal</code>"
    }
  })), "\n", React.createElement(_components.p, null, "He renombrado el paquete con el nombre ", React.createElement(_components.a, {
    href: "http://www.megaupload.com/?d=J0JCFET6"
  }, "pikdev_1.1.0-2_i386.deb"), ", y está colgado en megaupload para quien lo quiera todo mascadito."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
